import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExternalLayout from '../../components/layout/external-layout';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { GridList, GridListTile, makeStyles } from '@material-ui/core';
import { isMobile } from "react-device-detect";
import SimpleModal from '../../components/simple-modal';

const useStyles = makeStyles(() => ({
  rootGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    width: '100%',
  },
}));

export default function EnvironmentsPage() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState('Cozinha');
  const [imgSelected, setImgSelected] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const envs = {
    'Cozinha': [
      'contemporaneo/cozinha_gourmeteria.jpeg',
      'contemporaneo/cozinha_contemporanea.jpeg',
      'contemporaneo/cozinha_luminus.jpeg',
      'natural/cozinha_integrada.jpeg',
      'classico/cozinha_provencal.jpeg',
      'classico/cozinha_provencal_lambri.jpeg'
    ],
    'Dormitório': [
      'contemporaneo/dormitorio_contemporaneo.jpeg',
      'industrial/dormitorio_high_tech.jpeg',
      'classico/dormitorio_classico.jpeg',
      'industrial/comporativo_quarto_hotel.jpeg',
      'solteiro/quarto_masculino.jpeg',
      'solteiro/quarto_feminino.jpeg',
    ],
    'Closet': [
      'contemporaneo/dormitorio_closet.jpeg',
      'classico/dormitorio_closet_moderno.jpeg',
    ],
    'Sala': [
      'contemporaneo/sala_contemporanea.jpeg',
      'natural/sala_nordico.jpeg',
      'contemporaneo/sala_luminus.jpeg',
      'contemporaneo/sala_living_inverno.jpeg',
    ],
    'Studios': [
      'industrial/studios_degrade.jpeg',
      'industrial/studios_urbano.jpeg',
      'industrial/studios_londrino.jpeg'
    ],
    'Banheiro': [
      'contemporaneo/banheiro_luxo.jpeg',
      'contemporaneo/banheiro_lavabo_rosa.jpeg',
      'industrial/banheiro_industrial.jpeg',
    ],
    'Escritório': [
      'natural/studios_contemporaneo.jpeg',
      'contemporaneo/comporativo_office.jpeg',
      'natural/comporativo_sala_reunioes.jpeg',
      'natural/corporativo_coworking.jpeg',
    ],
  };

  const envsOptions = Object.keys(envs);

  const next = () => {
    const index = envsOptions.findIndex(env => env === selected);
    const newIndex = index + 1;

    setSelected(envsOptions[newIndex >= envsOptions.length ? 0 : newIndex]);
  };

  const prev = () => {
    const index = envsOptions.findIndex(env => env === selected);
    const newIndex = index - 1;

    setSelected(envsOptions[newIndex < 0 ? envsOptions.length - 1 : newIndex]);
  };

  return (
    <ExternalLayout>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button onClick={prev} size="large" style={{ background: 'white', color: 'black' }} variant="contained"><ChevronLeftIcon /></Button>
        <Button size="large" style={{ background: 'white', color: 'black', minWidth: 160 }} variant="contained" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          {selected}
        </Button>
        <Button onClick={next} size="large" style={{ background: 'white', color: 'black' }} variant="contained"><ChevronRightIcon /></Button>
      </ButtonGroup>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {envsOptions.map(env => {
          return (
            <MenuItem onClick={() => {
              setSelected(env);
              handleClose();
            }}>{env}</MenuItem>
          );
        })}
      </Menu>
      <br /><br />
      <div className={classes.rootGrid}>
        <GridList cellHeight={200} className={classes.gridList} cols={isMobile ? 1 : 3}>
          {envs[selected].map((img) => (
            <GridListTile key={img} cols={1}>
              <img class="hand-click" onClick={() => setImgSelected(img)} src={`/images/quiz/${img}`} />
            </GridListTile>
          ))}
        </GridList>
      </div>

      <SimpleModal
        title={selected}
        open={imgSelected}
        onClose={() => setImgSelected(undefined)}
        fullScreen={true}
      >
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            background: 'black',
          }}
        >
          <img src={`/images/quiz/${imgSelected}`} />
        </div>
      </SimpleModal>
    </ExternalLayout>
  );
}