import React, { useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from './dialog/common/dialog-content';
import DialogTitle from './dialog/common/dialog-title';
import Theme from './layout/theme';
import screenfull from 'screenfull';
import { isMobile } from "react-device-detect";

export default function SimpleModal({
  open,
  onClose,
  title,
  children,
  autoFullscreen,
  fullScreen
}) {
  const [fullscreen, setFullscreen] = React.useState(autoFullscreen ? true : fullScreen === undefined ? isMobile : fullScreen);

  useEffect(() => {
    if (open && autoFullscreen) {
      screenfull.request();
    }
  }, [open]);

  return (
    <Theme>
      <Dialog
        onClose={onClose}
        open={open}
        maxWidth="md"
        fullScreen={fullscreen}
        fullWidth
      >
        <DialogTitle
          title={title}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
          onClose={onClose}
        ></DialogTitle>

        <DialogContent dense style={{ minHeight: 500, padding: 0 }} dividers>
          {children}
        </DialogContent>
      </Dialog>
    </Theme>
  );
}
